import { useDashboard } from "@/contexts/dashboard/DashboardContext";
import SpinnerContainer from "@/components/dashboard/SpinnerContainer";
import { ResponsiveContainer, PieChart, Pie } from "recharts";
import EmptyBox from "@/components/animations/EmptyBox";
import TrendingUp from "@/components/svg/TrendingUp";
import LightningIcon from "@/components/svg/LightningIcon";
import PizzaGraphic from "@/components/svg/PizzaGraphic";

type PieDataProps = {
    name: string,
    value: number,
    fill: string
}

const MostUsedFormatsPieChart: React.FC = () => {
    const { mostUsedFormatsData, isFetchingMostUsedFormats } = useDashboard();

    const colors = ["#8884d8", "#82ca9d", "#ffc658", "#f58231", "#e6194B", "#3cb44b", "#f032e6"];

    const organizedData: Array<PieDataProps> = mostUsedFormatsData.map((data, index) => ({
        name: data.label,
        value: data.angle || 0,
        fill: colors[index % colors.length]
    })).filter((data) => data.value > 0)

    const countData = organizedData.reduce((acc, data) => (acc += data.value), 0);

    return (
        <div className='flex flex-col justify-start w-full h-full items-start overflow-hidden rounded-lg border bg-white' >
            {isFetchingMostUsedFormats && <SpinnerContainer />}
            <div className="flex items-center justify-between w-fit space-x-4 rounded-br-2xl px-4 py-1 border-b bg-gradient-to-r from-orange-500 to-amber-400">
                <span className="text-left text-xl font-semibold text-white">Tamanhos mais usados</span>
                <PizzaGraphic className="w-5 stroke-white" />
            </div>
            {
                countData > 0 ? (
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                            <Pie
                                dataKey="value"
                                startAngle={360}
                                endAngle={0}
                                data={organizedData}
                                cx="50%"
                                cy="50%"
                                outerRadius={100}
                                label={({ name }) => name}
                                paddingAngle={2}
                                className={"fill-blue-500"}
                            />
                        </PieChart>
                    </ResponsiveContainer>
                ) : (
                    <div className="w-full h-full flex justify-center items-center">
                        <EmptyBox height={250} animation="yellow-box" loop={false} />
                    </div>
                )
            }
        </div>
    )
}

export default MostUsedFormatsPieChart;
