import React from 'react';
import Navbar from '@/components/dashboard/Navbar';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { ReactChildren } from '@/types';
import MonthlyView from '@/components/dashboard/widgets/MonthlyView';
import MostUsedFormatsPieChart from '@/components/dashboard/widgets/MostUsedFormatsPieChart';
import TemplateHanking from '@/components/dashboard/widgets/TemplateHanking';
import CanvasSidebar from '@/components/sidebars/canvas/CanvasSidebar';
import FavoriteTemplates from '@/components/dashboard/widgets/FavoriteTemplates';
import { SaveTemplateProvider } from '@/contexts/modals/saveTemplate/SaveTemplateContext';
import useRequireLogin from '@/hooks/useRequireLogin';

interface DashboardInterface { }

const ResponsiveGridLayout = WidthProvider(Responsive);

const __BOX_CLASS_NAME__ = "w-full h-full rounded-lg";

const __QUEUE_PANEL_OPTIONS__ = {
    w: 5,
    h: 1,
    x: 3,
    isDraggable: true
};

const __TEMPLATE_RANKING_OPTIONS__ = {
    w: 4,
    h: 4,
};

const __MOST_USED_FORMATS_OPTIONS__ = {
    w: 4,
    h: 4,
    x: 4,
};

const __FAVORITE_TEMPLATES_OPTIONS__ = {
    w: 4,
    h: 4,
    x: 8,
};

const __MONTHLY_VIEW_OPTIONS__ = {
    w: 16,
    h: 4,
    y: 4,
    minW: 4,
    minH: 3,
};

// Função externa para gerar os itens do painel
const generatePanelData = (id: string, content: ReactChildren, options = {}) => {
    const defaultOptions = {
        x: 0,
        y: 0,
        w: 2,
        h: 2,
        isDraggable: true,
        // isResizable: true,
    };

    const panel = {
        id: id,
        content: content,
        ...defaultOptions,
        ...options
    };

    return panel;
};

const Dashboard: React.FC<DashboardInterface> = () => {
    useRequireLogin();

    const panelData = [
        // generatePanelData('queue_counter', <QueuePanel />, __QUEUE_PANEL_OPTIONS__),
        generatePanelData('favorite_templates', <FavoriteTemplates />, __FAVORITE_TEMPLATES_OPTIONS__),
        generatePanelData('most_used_formats', <MostUsedFormatsPieChart />, __MOST_USED_FORMATS_OPTIONS__),
        generatePanelData('template_hanking', <TemplateHanking />, __TEMPLATE_RANKING_OPTIONS__),
        generatePanelData('monthly_view', <MonthlyView />, __MONTHLY_VIEW_OPTIONS__),
    ];

    const panelSizeMd = panelData.map(panel => {
        let options: Record<string, number> = {};

        if (panel.id === 'template_hanking') options['w'] = 8;
        else if (panel.id === 'favorite_templates') {
            options['x'] = 0;
            options['w'] = 5;
        }

        return {
            ...panel,
            ...options,
            i: panel.id
        }
    });

    const layouts = {
        lg: panelData.map(panel => ({
            ...panel,
            i: panel.id
        })),
        md: panelSizeMd,
        sm: panelData.map(panel => ({
            ...panel,
            w: 4,
            i: panel.id
        }))
    };

    return (
        <div className="max-h-screen min-h-[80vh] h-screen w-full flex flex-col overflow-hidden bg-zinc-50">
            <Navbar />
            <SaveTemplateProvider>
                <CanvasSidebar />
            </SaveTemplateProvider>
            <div className="flex-grow flex justify-center h-full max-h-[85vh]">
                <div className="w-full h-full min-h-full max-w-full overflow-x-hidden">
                    <ResponsiveGridLayout
                        className="layout min-h-full"
                        layouts={layouts}
                        breakpoints={{ lg: 1200, md: 996, sm: 768 }}
                        cols={{ lg: 12, md: 8, sm: 4 }}
                        rowHeight={80}
                        margin={[20, 20]}
                        isResizable={false}
                        isDraggable={false}
                        isDroppable={false}
                    >
                        {
                            panelData.map(panel => (
                                <div key={panel.id} className={`${__BOX_CLASS_NAME__}`}>
                                    {panel.content}
                                </div>
                            ))
                        }
                    </ResponsiveGridLayout>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
