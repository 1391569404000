import React, { useState } from 'react';
import Spinner from '../svg/Spinner';
import { useDashboard } from '@/contexts/dashboard/DashboardContext';

const Navbar: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { refreshDashboard } = useDashboard();

    const onClick = async () => {
        setIsLoading(true);
        refreshDashboard(() => setIsLoading(false));
    }

    return (
        <nav className="bg-white border-b p-2 px-4 pt-4 flex justify-between items-center">
            <h1 className="text-zinc-600 text-lg font-bold">Dashboard</h1>
            <div>
                <div className='w-full h-full flex flex-row justify-center items-center space-x-4'>
                    {
                        isLoading && <div className='text-gray-600 mt-2'><Spinner /></div>
                    }
                    <button onClick={onClick} className="bg-green-500 shadow-md shadow-green-500/50 text-white py-2 px-4 rounded-lg">Atualizar</button>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
