import { useDashboard } from "@/contexts/dashboard/DashboardContext";
import SpinnerContainer from "@/components/dashboard/SpinnerContainer";
import { useCanvas, useCanvasDispatch } from "@/contexts/editor/CanvasContext";
import { useModals } from "@/contexts/modals/ModalsContext";
import EmptyBox from "@/components/animations/EmptyBox";
import TrendingUp from "@/components/svg/TrendingUp";

const TemplateHanking: React.FC = () => {
	const { isFetchingTemplateHanking, templateHankingList } = useDashboard();
	const { reloadTemplate } = useCanvas();
	const { stateDispatch } = useCanvasDispatch();
	const { setShowCanvasModal } = useModals();

	const onClick = async (hash: string) => {
		if (!hash) return;
		stateDispatch({ type: "setTemplateId", templateId: hash });
		await reloadTemplate(hash);
		setShowCanvasModal(true);
	}

	return (
		<div className='flex flex-col w-full h-full items-start overflow-hidden rounded-lg border bg-white' >
			{isFetchingTemplateHanking && <SpinnerContainer />}
			<div className="flex items-center justify-between w-fit space-x-4 rounded-br-2xl border-b px-4 py-1 bg-gradient-to-r from-orange-500 to-amber-400">
				<span className="text-white text-left text-xl font-semibold" >Templates mais usados</span>
				<TrendingUp color="text-white" />
			</div>
			<div className="w-full h-full overflow-y-auto">
				<ul className="w-full h-full">
					{
						templateHankingList.length > 0 ? (
							templateHankingList.map((item) => (
								<li key={item.hash} className="cursor-default border-b w-full flex justify-between items-center px-4 py-2 hover:bg-zinc-50" >
									<span className="w-full text-zinc-500" >{item.campaign}</span>
									<button className="w-1/5 min-w-fit font-semibold text-blue-500 py-1" onClick={() => onClick(item.hash)} >Abrir</button>
								</li>
							))
						) : <div className="w-full h-full flex justify-center items-center"><EmptyBox loop={false} animation="yellow-box" height={250} /></div>
					}
				</ul>
			</div>
		</div>
	)
}

export default TemplateHanking;
