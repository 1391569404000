import { useDashboard } from "@/contexts/dashboard/DashboardContext";
import SpinnerContainer from "@/components/dashboard/SpinnerContainer";
import { useCanvas, useCanvasDispatch } from "@/contexts/editor/CanvasContext";
import { useModals } from "@/contexts/modals/ModalsContext";
import Api from "@/api/Api";
import { useMember } from "@/contexts/member/MemberContext";
import Notify from "@/helpers/Notify";
import { useRef, useState } from "react";
import React from "react";
import DangerConfirmationModal from "@/components/modals/warnings/DangerConfirmationModal";
import { EntityID } from "@/types/silverstripe";
import EmptyBox from "@/components/animations/EmptyBox";
import { StarIcon } from "@/components/svg/StarIcon";
import ThreeDotsIcon from "@/components/svg/ThreeDotsIcon";
import { FavoriteTemplate } from "@/types/dashboard";

const FavoriteTemplates: React.FC = () => {
	const { member } = useMember();
	const { isFetchingFavoriteTemplateList, favoriteTemplateList, refreshDashboard } = useDashboard();
	const { reloadTemplate } = useCanvas();
	const { stateDispatch } = useCanvasDispatch();
	const { setShowCanvasModal } = useModals();

	const [isDeleting, setIsDeleting] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [showOptions, setShowOptions] = useState('');

	const targetID = useRef<EntityID>(null);

	const closeDeleteModal = () => {
		if (targetID.current) targetID.current = null;
		setShowDeleteModal(false);
	}

	const onClick = async (hash: string) => {
		if (!hash) return;
		stateDispatch({ type: "setTemplateId", templateId: hash });
		await reloadTemplate(hash);
		setShowCanvasModal(true);
	}

	const handleRemove = (hash: string) => {
		targetID.current = hash;
		setShowDeleteModal(true);
	}

	const do_delete_favorite_template = () => {
		if (isDeleting || !targetID.current) return;

		setIsDeleting(true);

		const api = new Api('templates', 'uf');
		const request = api.request(member, { templateId: targetID.current });
		api.post(request).then((response) => {
			if (response.success) {
				Notify.Info('Removido dos favoritos.');
				refreshDashboard();
			}
		}).finally(() => setIsDeleting(false));
	}

	const handleOptionMenu = (hash: string) => {
		if (showOptions === hash) return setShowOptions('');

		setShowOptions(hash);
	}

	return (
		<>
			<div className='flex flex-col w-full h-full items-start justify-start overflow-hidden rounded-lg border bg-white' >
				{isFetchingFavoriteTemplateList && <SpinnerContainer />}
				<div className="flex items-center justify-between w-fit space-x-4 rounded-br-2xl px-4 py-1 border-b bg-gradient-to-r from-orange-500 to-amber-400">
					<span className="text-left text-xl font-semibold text-white">Meus Favoritos</span>
					<StarIcon color="fill-white" />
				</div>
				<div className="w-full h-full overflow-y-auto">
					<ul className="w-full">
						{
							favoriteTemplateList.length > 0 ? (
								favoriteTemplateList.map(item => (
									<li key={item.hash} className="relative w-full border-b min-h-10 flex flex-row justify-around items-center px-4 py-3 hover:bg-zinc-50 duration-300">
										<span className="w-full cursor-default text-zinc-500">{item.name}</span>
										<button onClick={() => handleOptionMenu(item.hash)}>
											<ThreeDotsIcon color="stroke-zinc-500" />
										</button>
										{showOptions === item.hash && (
											<MenuOptionsFavoriteTemplates
												handleRemove={handleRemove}
												openTemplate={onClick}
												item={item}
											/>
										)}
									</li>
								))
							)
								:
								<div className="w-full h-full flex justify-center items-center">
									<EmptyBox height={250} animation="mag-glass" />
								</div>
						}
					</ul>
				</div>
			</div>
			<DangerConfirmationModal
				isOpen={showDeleteModal}
				onClose={closeDeleteModal}
				onConfirm={do_delete_favorite_template} message={<DeleteModalContent />}
			/>
		</>
	)
}

export default FavoriteTemplates;

interface IMenuOptionsFavoriteTemplates {
	handleRemove: (hash: string) => void;
	openTemplate: (hash: string) => void;
	item: FavoriteTemplate;
}

const MenuOptionsFavoriteTemplates: React.FC<IMenuOptionsFavoriteTemplates> = ({ handleRemove, openTemplate, item }) => {
	return (
		<div className="absolute border rounded-lg w-fit h-fit bg-white shadow top-10 right-2 z-20 flex flex-col items-center justify-center space-x-1">
			<button
				className={`hover:bg-zinc-50 w-full border-b flex items-center justify-between px-3 py-2 rounded text-rose-500 fill-rose-500 font-medium`}
				onClick={() => handleRemove(item.hash)}
			>
				Remover
			</button>
			<button
				className="hover:bg-zinc-50 w-full text-blue-500 px-3 py-2 font-medium"
				onClick={() => openTemplate(item.hash)}
			>
				Abrir
			</button>
		</div>
	);
}

const DeleteModalContent: React.FC = () => {
	return (
		<span>Remover template dos seus favoritos?</span>
	)
}
